import styled, { css } from 'styled-components'

export const ContainerNotFound = styled.div<{ $fullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.spacings.xxsmall};

  width: 100%;
  ${({ $fullHeight }) =>
    $fullHeight &&
    css`
      height: 100vh;
    `}

  background-color: ${({ theme }) => theme.colors.black};

  color: ${({ theme }) => theme.colors.white};

  > .subTitle {
    font-size: ${({ theme }) => theme.font.sizes.medium};
    font-size: 2rem;
  }
  > .title {
    font-size: 1.6rem;
  }
`

export const FooterResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: ${({ theme }) => theme.spacings.xsmall};
`
