'use client'

import { useRouter } from 'next/navigation'

import { ButtonUI } from '@/components/UI/ButtonUI'

import * as S from './styles'

interface IProps {
  fullHeight?: boolean
  subTitle?: string
}

export function NotFoundComponent({
  fullHeight = true,
  subTitle = 'Desculpe, a página que você visitou não existe.'
}: IProps) {
  const router = useRouter()
  return (
    <S.ContainerNotFound $fullHeight={fullHeight}>
      <div className="subTitle">
        <span>{subTitle}</span>
      </div>
      <div className="title">
        <span>404</span>
      </div>
      <S.FooterResult>
        <ButtonUI
          onClick={() => router.replace('/')}
          size="medium"
          buttonType="tertiary"
        >
          Ir para a página inicial
        </ButtonUI>
      </S.FooterResult>
    </S.ContainerNotFound>
  )
}
